import { AnyAction } from 'redux'
import { RootState } from '../../../store'
import { IResponseStatus } from '../reducers'
import * as Constants from '../constants'
import * as Services from '../services'

import { takeLatest, put, call, select } from 'redux-saga/effects'
import { parseResponse } from '../../../store/saga/responseParser'

function* getPrescriptionsSummary(action: AnyAction) {
  console.log(
    `saga PrescriptionsSummary start action ${JSON.stringify(action)}`,
  )
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.prescriptionsSummary,
      api_key,
      action.patientId,
    )
    yield call(
      parseResponse,
      response,
      Constants.GET_PRESCRIPTIONS_SUMMARY_SUCCESS,
      Constants.GET_PRESCRIPTIONS_SUMMARY_FAILED,
    )
    console.log(`saga prescription ${JSON.stringify(response)}`)
    response.data.error ? action.reject(response.data.error) : action.resolve()
  } catch (error) {
    console.log(`Saga catch error ${JSON.stringify(error)}`)
    yield put({ type: Constants.GET_PRESCRIPTIONS_SUMMARY_FAILED, error })
  }
  console.log(`saga PrescriptionSummary complete`)
}

function* getPrescriptions(action: AnyAction) {
  console.log(`saga Prescriptions start action ${JSON.stringify(action)}`)

  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.prescriptions,
      api_key,
      action.page,
      action.limit,
      action.state,
      action.read,
      action.patientId,
    )
    yield call(
      parseResponse,
      response,
      Constants.GET_PRESCRIPTIONS_SUCCESS,
      Constants.GET_PRESCRIPTIONS_FAILED,
    )
    response.data.error ? action.reject(response.data.error) : action.resolve()
  } catch (error) {
    console.log(`Saga catch error ${JSON.stringify(error)}`)
    yield put({ type: Constants.GET_PRESCRIPTIONS_SUMMARY_FAILED, error })
  }
  console.log(`saga Prescription complete`)
}

function* getPrescriptionPdf(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.getPrescriptionPdf,
      api_key,
      action.pdfName,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_PRESCRIPTION_PDF_SUCCESS,
      Constants.GET_PRESCRIPTION_PDF_FAILED,
    )
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_PRESCRIPTION_PDF_FAILED, error })
  }
}

function* updatePrescriptionMakerReaded(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token
  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.updatePrescriptionMakerReaded,
      api_key,
      action.prescription_id,
      action.maker_readed,
      action.maker_id,
    )
    yield call(
      parseResponse,
      response,
      Constants.GET_PRESCRIPTION_PDF_SUCCESS,
      Constants.GET_PRESCRIPTION_PDF_FAILED,
    )
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({
      type: Constants.UPDATE_PRESCRIPTION_MAKER_READED_FAILED,
      error,
    })
  }
}

export const prescriptionsSagas = [
  takeLatest(Constants.GET_PRESCRIPTIONS_SUMMARY, getPrescriptionsSummary),
  takeLatest(Constants.GET_PRESCRIPTIONS, getPrescriptions),
  takeLatest(Constants.GET_PRESCRIPTION_PDF, getPrescriptionPdf),
  takeLatest(
    Constants.UPDATE_PRESCRIPTION_MAKER_READED,
    updatePrescriptionMakerReaded,
  ),
]
