import { toast } from 'react-toastify'
import { call, put } from 'redux-saga/effects'
import { TOGGLE_LOGOUT } from '../../modules/auth/constants'

export function* parseResponse(response: any, success: string, failed: string) {
  switch (response.status) {
    case 200:
      yield put({ type: success, payload: response })
      break
    case 201:
      yield put({ type: success, payload: response })
      break
    case 304:
      yield put({ type: success, payload: response })
      break
    case 401:
      yield call(toast.error, 'Connexion expirée')
      yield put({ type: TOGGLE_LOGOUT })
      break
    default:
      yield call(
        toast.error,
        'Erreur ' + response.data.message
          ? response.data.message
          : response.status,
      )
      yield put({ type: failed })
  }
}
