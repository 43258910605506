import { RootState } from '../../../store'
import { connect, ConnectedProps } from 'react-redux'
import { withRouter } from 'react-router-dom'
import PatientInfoCompenent from '../components/PatientInfoComponent'
import {
  addImplant,
  affectTransmitter,
  createTransmitter,
  deleteImplant,
  deleteTransmitter,
  getAgreement,
  getCountry,
  getPatientByIdService,
  postAgreement,
  postNewPatientNote,
  postNewPatientStatus,
  updateImplant,
  updatePatient,
  updatePatientDoctor,
  updatePatientHistory,
  updatePatientTreatment,
} from '../actions'
import {
  IAddImplant,
  IAffectTransmitter,
  INote,
  IPatientInfos,
  IStatus,
  IUpdateImplant,
} from '../reducers'
import {
  addDoctorStep1,
  addDoctorStep2,
  addDoctorStep3,
  addDoctorStep4,
  getDoctorAutocomplete,
  getDoctors,
} from '../../doctors/actions'
import { IDoctor } from '../reducers'
import {
  deletePatientProsthesis,
  getProsthesisReferences,
  updatePatientProsthesis,
} from '../../../modules/protheses/actions'
import { IUpdatePatientProsthesis } from 'src/modules/protheses/reducers'
import {
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from 'src/modules/doctors/reducers'
import {
  getTransmitterReferences,
  updatePatientReasons,
  postPatientPrescription,
} from './../actions'

const mapStateToProps = (state: RootState) => {
  return {
    user: state.authReducer.user,
    token: state.authReducer.token,
    patientInfo: state.patientsReducer.patientInfo,
    transmitterReferences: state.patientsReducer.transmitterReferences,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  return {
    getPatientByIdService: (id: string) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getPatientByIdService(id, resolve, reject))
      })
    },
    postNewPatientNote: (note: INote) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(postNewPatientNote(note, resolve, reject))
      })
    },
    postNewPatientStatus: (status: IStatus) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(postNewPatientStatus(status, resolve, reject))
      })
    },
    getDoctors: (
      size: number,
      page: number,
      doctorType: string,
      search: string,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getDoctors(size, page, doctorType, search, resolve, reject))
      })
    },
    updatePatient: (patient: IPatientInfos) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updatePatient(patient, resolve, reject))
      })
    },
    addImplant: (infos: IAddImplant) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(addImplant(infos, resolve, reject))
      })
    },
    updatePatientDoctor: (patientId: number, doctor: IDoctor) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updatePatientDoctor(patientId, doctor, resolve, reject))
      })
    },
    updatePatientHistory: (patientId: number, history: number[]) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updatePatientHistory(patientId, history, resolve, reject))
      })
    },
    updatePatientTreatment: (patientId: number, treatment: number[]) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updatePatientTreatment(patientId, treatment, resolve, reject))
      })
    },
    updateImplant: (infos: IUpdateImplant) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updateImplant(infos, resolve, reject))
      })
    },
    getProsthesisReferences: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getProsthesisReferences('', [], [], resolve, reject))
      })
    },
    deleteImplant: (patientId: number, implantId: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(deleteImplant(patientId, implantId, resolve, reject))
      })
    },
    updatePatientProsthesis: (
      infos: IUpdatePatientProsthesis,
      method: 'POST' | 'PATCH',
      implantationId: number,
      patientId: number,
      cardioAttent: IDoctor,
      cardioBilling: IDoctor,
      cardioImplant: IDoctor,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          updatePatientProsthesis(
            infos,
            method,
            implantationId,
            patientId,
            cardioAttent,
            cardioBilling,
            cardioImplant,
            resolve,
            reject,
          ),
        )
      })
    },
    deletePatientProsthesis: (id: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(deletePatientProsthesis(id, resolve, reject))
      })
    },
    getDoctorAutocomplete: (name: string, page: number, limit: number) => {
      return new Promise(
        (resolve: (doctor: IFoundDoctor[]) => void, reject: any) => {
          dispatch(getDoctorAutocomplete(name, page, limit, resolve, reject))
        },
      )
    },
    getCountries: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getCountry(resolve, reject))
      })
    },
    addDoctor: {
      step1: (doctor: INewDoctorStep1) => {
        return new Promise((resolve: (id: number) => void, reject: any) => {
          dispatch(addDoctorStep1(doctor, resolve, reject))
        })
      },
      step2: (id: number) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep2(id, resolve, reject))
        })
      },
      step3: (id: number, address: INewDoctorAddress) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep3(id, address, resolve, reject))
        })
      },
      step4: (id: number, doctorInfos: INewDoctorInfos) => {
        return new Promise((resolve: any, reject: any) => {
          dispatch(addDoctorStep4(id, doctorInfos, resolve, reject))
        })
      },
    },
    updatePatientReasons: (implantationId: number, reasons: number[]) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(updatePatientReasons(implantationId, reasons, resolve, reject))
      })
    },
    getTransmitterReferences: () => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(getTransmitterReferences(resolve, reject))
      })
    },
    createTransmitter: (
      referenceId: number,
      serialNumber: string,
      method: 'POST' | 'PATCH',
      transmitterId?: number,
    ) => {
      return new Promise((resolve: (id: number) => void, reject: any) => {
        dispatch(
          createTransmitter(
            referenceId,
            serialNumber,
            method,
            resolve,
            reject,
            transmitterId,
          ),
        )
      })
    },
    affectTransmitter: (
      infos: IAffectTransmitter,
      method: 'POST' | 'PATCH',
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(affectTransmitter(infos, method, resolve, reject))
      })
    },
    postAgreement: (
      transmitterId: number,
      file: string | ArrayBuffer | null | File,
    ) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(postAgreement(transmitterId, file, resolve, reject))
      })
    },
    deleteTransmitter: (transmitterId: number, affectationId: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(
          deleteTransmitter(transmitterId, affectationId, resolve, reject),
        )
      })
    },
    getAgreement: (id: number) => {
      return new Promise((resolve: (pdf: Blob) => void, reject: any) => {
        dispatch(getAgreement(id, resolve, reject))
      })
    },
    postPatientPrescription: (patientId: number) => {
      return new Promise((resolve: any, reject: any) => {
        dispatch(postPatientPrescription(patientId, resolve, reject))
      })
    },
  }
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export type PropsFromReduxPatientInfo = ConnectedProps<typeof connector>

export default withRouter(connector(PatientInfoCompenent))
