import { REFACTOR_URL } from '../../../helpers/env'
import { API_URL } from '../constants'
import {
  ICreatePatient,
  INote,
  IStatus,
  IAddPatientCenter,
  PatientType,
  IAddImplant,
  IPatientInfos,
  IUpdateImplant,
  IAffectTransmitter,
  IUpdateProsthesisId,
} from '../reducers'
import { IDoctor } from '../../../modules/patients/reducers'
import { GridSortDirection } from '@mui/x-data-grid-pro'

export const countryService = (token: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/country`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const companyService = (token: string, page: number, size: number) => {
  const ENDPOINT: RequestInfo = `${API_URL}/company?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const prosthesisGammeRangeService = (
  token: string,
  page: number,
  size: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/prosthesis/gamme-range?page=${page}&size=${size}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const patientService = (
  token: string,
  page: number,
  size: number,
  patientsType: PatientType,
  searchText: string,
  gammeIds: string[],
  makerIds: number[],
  sort: { field: string; sort: GridSortDirection } | null,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/patients?page=${page}&limit=${size}&type=${patientsType}&search=${searchText}${
    gammeIds.length > 0
      ? gammeIds.map((gammeId) => `&prosthesisTypes[]=${gammeId}`).join('')
      : ''
  }${
    makerIds.length
      ? makerIds.map((makerId) => `&makerIds[]=${makerId}`).join('')
      : ''
  }${sort ? `&sort=${sort.field}&order=${sort.sort}` : ''}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getPatientsSummary = (token: string) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/patients/summary`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const addPatientService = (token: string, patient: ICreatePatient) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/patients`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(patient),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getPatientByIdService = (token: string, id: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/patients/${id}`
  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientInfo = (token: string, patient: IPatientInfos) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(patient),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const addPatientNoteService = (token: string, note: INote) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${note.patientID}/note`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(note),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const changePatientStatusService = (token: string, status: IStatus) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${status.patientID}/status`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({ ...status }),

    mode: 'cors',
  }

  let responseStatus = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      responseStatus = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status: responseStatus }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const addPatientCenter = (
  token: string,
  id: number,
  center: IAddPatientCenter,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${id}/center`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(center),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const addImplant = (token: string, infos: IAddImplant) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${infos.patientId}/implantation`

  if (!infos.cardioAttentId) delete infos.cardioAttentId
  if (!infos.cardioBillingId) delete infos.cardioBillingId

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify({
      ...infos,
      prosthesisObject: {
        reference: {
          billableList: [],
          gammeId: 1,
          rangeId: 1,
          maker: null,
        },
      },
    }),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientDoctor = (
  token: string,
  patientId: number,
  doctor: IDoctor,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${patientId}/patientdoctor`

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(doctor),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientHistory = (
  token: string,
  patientId: number,
  history: number[],
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${patientId}/history`

  const body: any[] = []
  history.forEach((h) => body.push({ historyTypeId: h, patientId }))

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientTreatment = (
  token: string,
  patientId: number,
  treatment: number[],
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${patientId}/treatment`

  const body: any[] = []
  treatment.forEach((t) => body.push({ treatmentTypeId: t, patientId }))

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updateImplant = (
  token: string,
  infos: IUpdateImplant | IUpdateProsthesisId,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${infos.patientId}/implantation`

  if ('cardioBillingId' in infos && 'cardioAttentId' in infos) {
    if (!infos.cardioBillingId) delete infos.cardioBillingId
    if (!infos.cardioAttentId) delete infos.cardioAttentId
  }

  const parameters: RequestInit = {
    method: 'PATCH',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(infos),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const deleteImplant = (
  token: string,
  patientId: number,
  implantId: number,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/patient/${patientId}/implantation/${implantId}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const updatePatientReasons = (
  token: string,
  implantationId: number,
  reasons: number[],
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/implantation/${implantationId}/reason`

  const body: any[] = []
  reasons.forEach((reasonId) => body.push({ reasonId, implantationId }))

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    body: JSON.stringify(body),
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getTransmitterReferences = (token: string) => {
  const ENDPOINT: RequestInfo = `${API_URL}/transmitter-reference?page=0&size=1000`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const createTransmitter = (
  token: string,
  referenceId: number,
  serialNumber: string,
  method: 'POST' | 'PATCH',
  transmitterId?: number,
) => {
  const ENDPOINT: RequestInfo =
    method === 'POST'
      ? `${REFACTOR_URL}/transmitter`
      : `${REFACTOR_URL}/transmitter/${transmitterId}`

  const body: { referenceId: number; serialNumber: string; id?: number } = {
    referenceId,
    serialNumber,
  }
  if (method === 'PATCH') body.id = transmitterId

  const parameters: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(body),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const affectTransmitter = (
  token: string,
  infos: IAffectTransmitter,
  method: 'POST' | 'PATCH',
) => {
  console.log(`data to update ${JSON.stringify(infos)}`)
  const ENDPOINT: RequestInfo =
    method === 'POST'
      ? `${REFACTOR_URL}/transmitter-affectation`
      : `${REFACTOR_URL}/transmitter-affectation/${infos.id}`

  const parameters: RequestInit = {
    method,
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify(infos),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const postAgreement = (
  token: string,
  transmitterId: number,
  file: string,
) => {
  const ENDPOINT: RequestInfo = `${API_URL}/transmitter/${transmitterId}/affectation/agreement`
  const formData = new FormData()
  formData.append('file', file)
  formData.append('displayName', 'essaideFichier')

  const parameters: RequestInit = {
    method: 'POST',
    body: formData,
    headers: {
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const deleteTransmitter = (
  token: string,
  transmitterId: number,
  affectationId: number,
) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/transmitter-affectation/${affectationId}`

  const parameters: RequestInit = {
    method: 'DELETE',
    headers: {
      'Content-Type': 'application/json',
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}

export const getAgreement = (token: string, storeFileId: number) => {
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/stored_file/${storeFileId}`

  const parameters: RequestInit = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.blob()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.log(e)
    })
}

export const generatePerPatient = (token: string, patientId: number) => {
  console.log(`service generatePerPatient with patientId ${patientId}`)
  const ENDPOINT: RequestInfo = `${REFACTOR_URL}/prescriptions/patient`

  const parameters: RequestInit = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
      Host: `${API_URL}`,
      Authorization: `Bearer ${token}`,
    },
    mode: 'cors',
    body: JSON.stringify({
      patientId,
    }),
  }

  let status = 0

  return fetch(ENDPOINT, parameters)
    .then((response) => {
      status = response.status
      return response.json()
    })
    .then((data) => {
      return { data, status }
    })
    .catch((e) => {
      console.error(e)
    })
}
